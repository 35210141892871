import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GenerateButton from './GenerateButton'
import action from './actions/action'
import { InputText } from './components/InputText'
import { JobMaster } from './enums'
import './Input.css'
import { GlobalState } from './reducers/reducer'
import { isJapanese } from './utils'

const Select = styled.select`
  margin: 0;
  outline: none;
  height: 33px;
  padding: 3px 5px;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  background: #fff;
  width: 200px;
  @media (max-width: 480px) {
    width: 100%;
  }
`

const FileInput = styled.input`
  font-size: 16px;
`

interface InputProps {
  onClick: (e: React.FormEvent<HTMLInputElement>, id: string) => void
  inputRef: any
  alert: string[]
  dataLoading: boolean
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const dispatch = useDispatch()
  const id = useSelector((state: GlobalState) => state.id)
  console.log(id)
  const mainJob = useSelector((state: GlobalState) => state.mainJob)
  const { t, i18n } = useTranslation()
  const handleIdChange = (e: any) =>
    dispatch(action.updateId(e.target && e.target.value))
  const closeKeyboard = (e: any) => e.keyCode === 13 && e.target.blur()

  return (
    <div className="input">
      <dl>
        <dt>
          <label htmlFor="id">Lodestone ID</label>
        </dt>
        <dd>
          <InputText
            id="id"
            name="id"
            value={id}
            placeholder={t('id.placeholder')}
            onChange={handleIdChange}
            onKeyUp={closeKeyboard}
          />
          <span className="description">
            <a
              className="lodestone-link"
              href={t('lodestone.url')}
              target="_blank"
              rel="noreferrer noopener"
            >
              Lodestone
            </a>
            {t('id.description')}
            <br />
            <Image
              src={
                isJapanese(i18n.language)
                  ? 'https://firebasestorage.googleapis.com/v0/b/ff14-character-card.appspot.com/o/assets%2Flodestone_description_ja.jpg?alt=media&token=8a8c0b5e-0cf1-4805-89ae-e64f2e58d810'
                  : 'https://firebasestorage.googleapis.com/v0/b/ff14-character-card.appspot.com/o/assets%2Flodestone_description_en.jpg?alt=media&token=bbe288fd-10f3-4f46-8985-6c14f1d5d93d'
              }
              alt="Description of lodestone id"
            />
          </span>
        </dd>
        <dt>
          <label htmlFor="mainJob">{t('mainJob.title')}</label>
        </dt>
        <dd>
          <Select
            id="mainJob"
            name="mainJob"
            value={mainJob}
            onChange={(event) =>
              dispatch(action.updateMainJob(event.target.value))
            }
          >
            {JobMaster.map((job) => (
              <option key={job.id} value={job.id}>
                {t(job.key)}
              </option>
            ))}
          </Select>
        </dd>
        <dt>
          <label htmlFor="inputImage">{t('inputImage.title')}</label>
        </dt>
        <dd>
          <FileInput
            type="file"
            name="file"
            id="inputImage"
            ref={props.inputRef}
          />
        </dd>
      </dl>
      <ul className="alert">
        {props.alert.map((str) => {
          return <li key={str}>{str}</li>
        })}
      </ul>
      <GenerateButtonWrapper>
        <GenerateButton
          onClick={(e: React.FormEvent<HTMLInputElement>) =>
            props.onClick(e, id)
          }
          dataLoading={props.dataLoading}
        />
      </GenerateButtonWrapper>
    </div>
  )
}

const GenerateButtonWrapper = styled.div`
  margin-top: 20px;
`
const Image = styled.img`
  width: 360px;
  max-width: 100%;
  border: 4px solid #222;
`

export default Input
