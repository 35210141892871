import React from 'react'
import Main from './Main'
import './App.css'
import { Footer } from './components/Footer'
import { Header } from './components/Header'

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  )
}

export default App
