import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Header: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Image
        src="https://firebasestorage.googleapis.com/v0/b/ff14-character-card.appspot.com/o/assets%2Fheader.jpg?alt=media&token=98cab887-8983-4df4-96b6-a4e0c93d892f"
        alt="header image"
      />
      <Title>{t('title')}</Title>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  width: 100%;
  padding-top: min(25%, 200px);
  max-height: 153px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
const Image = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Title = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #49b1dc;
  border: min(1vw, 8px) solid #49b1dc;
  background: #444444;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 768px;
  max-width: 70%;
  height: 70%;
  font-size: min(8.5vw, 76px);
`
