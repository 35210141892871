import styled from '@emotion/styled'
import React from 'react'

const Input = styled.input`
  margin: 0;
  outline: none;
  box-sizing: border-box;
  width: 200px;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  background: #fff;
  @media (max-width: 480px) {
    width: 100%;
  }
  :focus {
    background-color: #d8dcf6;
  }
`

interface Props {
  id: string
  name: string
  value: string
  onChange: (e: any) => void
  placeholder: string
  onKeyUp: (e: any) => void
}

export const InputText: React.FC<Props> = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  onKeyUp,
}) => {
  return (
    <Input
      id={id}
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required
      onKeyUp={onKeyUp}
    />
  )
}
