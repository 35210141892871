import styled from '@emotion/styled'
import React, { useMemo, useRef, useState } from 'react'
import * as ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { Element, scroller } from 'react-scroll'
import Character from './Character'
import Input from './Input'
import Output from './Output'
import { AdsenseAd } from './components/AdsenseAd'
import { useWindowSize } from './hooks/useWindowSize'

ReactGA.initialize('UA-71295058-3')
ReactGA.pageview(window.location.pathname + window.location.search)

const apiDomain = 'https://characa.onrender.com'

const Main: React.FC = () => {
  const fileInput = useRef<HTMLInputElement>()
  const [character, setCharacter] = useState(new Character())
  const [inputImage, setInputImage] = useState('')
  const [draw, setDraw] = useState(false)
  const [alert, setAlert] = useState([] as string[])
  const [dataLoading, setDataLoading] = useState(false)
  const { t } = useTranslation()
  const size = useWindowSize()

  const windowWidth = useMemo(() => size.width, [size.width])

  const validate = (id: string, imageUrl: string) => {
    const alert = []
    if (!id) {
      alert.push(t('alert.inputId'))
    } else if (isNaN(Number(id))) {
      alert.push(t('alert.invalidId'))
    }
    if (!imageUrl) {
      alert.push(t('alert.selectImage'))
    }
    return alert
  }

  const scrollToOutput = () => {
    scroller.scrollTo('output', {
      duration: 400,
      delay: 30,
      smooth: true,
    })
  }

  const handleClick = async (
    e: React.FormEvent<HTMLInputElement>,
    id: string
  ) => {
    ReactGA.ga('send', 'event', 'Click', 'generate')

    let imageUrl = ''
    if (
      fileInput.current &&
      fileInput.current.files &&
      fileInput.current.files[0]
    ) {
      imageUrl = URL.createObjectURL(fileInput.current.files[0])
    }

    // imageUrl = imageUrl || 'https://firebasestorage.googleapis.com/v0/b/ff14-character-card.appspot.com/o/assets%2Ftest.jpg?alt=media&token=a3cc7dbb-6d6f-4b73-b7ac-d40b8b622a3f';

    setAlert([])
    const alert = validate(id, imageUrl)
    if (alert.length > 0) {
      setAlert(alert)
      return
    }

    setDraw(false)
    setDataLoading(true)

    const res = await fetch(apiDomain + '/character?id=' + id, { mode: 'cors' })
    const character = new Character(await res.json())

    setCharacter(character)
    setInputImage(imageUrl)

    setDraw(true)
    setDataLoading(false)

    scrollToOutput()
  }

  return (
    <Wrapper>
      {windowWidth && windowWidth < 700 && (
        <SpAdWrapper>
          <AdsenseAd adType="underTheHeader" responsive />
        </SpAdWrapper>
      )}
      {windowWidth && windowWidth >= 700 && (
        <PcAdWrapper>
          <AdsenseAd adType="pcTopLarge" />
        </PcAdWrapper>
      )}
      <MainContent>
        <Input
          onClick={handleClick}
          inputRef={fileInput}
          alert={alert}
          dataLoading={dataLoading}
        />
        <OutputWrapper>
          <Element name="output">
            <Output draw={draw} character={character} inputImage={inputImage} />
          </Element>
        </OutputWrapper>
      </MainContent>
      {draw && (
        <AdsenseArea>
          {windowWidth && windowWidth < 700 && (
            <SpAdWrapper>
              <AdsenseAd adType="spBottom" responsive />
            </SpAdWrapper>
          )}
          {draw && windowWidth && windowWidth >= 700 && (
            <PcAdWrapper>
              <AdsenseAd adType="pcBottomLarge" />
            </PcAdWrapper>
          )}
        </AdsenseArea>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.main`
  width: 100%;
  flex: 1 0 auto;
  background: #444;
`
const MainContent = styled.div`
  margin: 24px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const OutputWrapper = styled.div`
  margin-top: 32px;
`
const AdsenseArea = styled.div`
  margin: 28px 0;
`
const SpAdWrapper = styled.div`
  display: flex;
  justify-content: center;

  ins {
    width: 100%;
    max-width: 480px;
  }
`
const PcAdWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ins {
    min-width: 50px;
    width: 768px;
    max-width: 100%;
    height: 180px;
  }

  &:nth-of-type(n + 2) {
    margin-left: 24px;
  }
`

export default Main
