import React from 'react'
import './Button.css'

const DownloadButton: React.FC<{ onClick: any }> = (props: {
  onClick: any
}) => {
  return (
    <div className="button-wrapper">
      <button className="ladda-button green contract" onClick={props.onClick}>
        <span className="label">Download</span>
        <span className="spinner" />
      </button>
    </div>
  )
}

export default DownloadButton
