import styled from '@emotion/styled'
import React, { useMemo, useState } from 'react'
import * as ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Canvas from './Canvas'
import Customize from './Customize'
import DownloadButton from './DownloadButton'
import TweetComposeButton from './TweetComposeButton'
import { FontSetMaster } from './enums'
import { useWindowSize } from './hooks/useWindowSize'
import { GlobalState } from './reducers/reducer'

const Output: React.FC<any> = (props: any) => {
  let canvas: any = null
  const mainJob = useSelector((state: GlobalState) => state.mainJob)
  const playTimeZones = useSelector((state: GlobalState) => state.playTimeZones)
  const playStyles = useSelector((state: GlobalState) => state.playStyles)
  const infoAlign = useSelector((state: GlobalState) => state.infoAlign)
  const opacity = useSelector((state: GlobalState) => state.opacity)
  const infoTheme = useSelector((state: GlobalState) => state.infoTheme)
  const font = useSelector((state: GlobalState) => state.font)
  const { t } = useTranslation()
  const [hasDownload, setHasDownload] = useState(false)

  const size = useWindowSize()
  const windowWidth = useMemo(() => size.width, [size.width])

  if (!props.draw) {
    return <div />
  }

  return (
    <Wrapper>
      <Canvas
        ref={(ref) => (canvas = ref)}
        character={props.character}
        mainJob={mainJob}
        playTimeZones={playTimeZones}
        playStyles={playStyles}
        infoAlign={infoAlign}
        opacity={opacity}
        infoTheme={infoTheme}
        inputImage={props.inputImage}
        fontSet={FontSetMaster.find((f) => f.id === font) || FontSetMaster[0]}
      />

      <Customize />

      <DownloadButton
        onClick={() => {
          setHasDownload(true)
          canvas.handleDownloadClick()
        }}
      />
      <DownloadDescriptionWrapper>
        <DownloadDescription>{t('downloadDescription')}</DownloadDescription>
      </DownloadDescriptionWrapper>
      <ButtonWrapper>
        <TweetComposeButton
          name={props.character.name}
          server={props.character.server}
          dataCenter={props.character.data_center}
          onClick={() => {
            if (!hasDownload) {
              ReactGA.ga('send', 'event', 'Click', 'tweet', 'without download')
              alert(t('downloadDescription'))
            } else {
              ReactGA.ga('send', 'event', 'Click', 'tweet', 'has download')
            }
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DownloadDescriptionWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  padding: 0 12px;
`
const DownloadDescription = styled.p`
  font-size: 1.2em;
  font-weight: bold;
  color: #ff643f;
`
const ButtonWrapper = styled.div`
  margin-top: 20px;
`

export default Output
