import React from 'react'
import './Button.css'
import { useTranslation } from 'react-i18next'

const TweetComposeButton: React.FC<{
  name: string
  server: string
  dataCenter: string
  onClick: any
}> = ({ server, dataCenter, onClick }) => {
  const { t } = useTranslation()

  const hashtags = `${t('tweet.hashtags', { dataCenter, server })}`
  const siteUrl = 'https://characa.kkimu.dev'
  const path = window.navigator.userAgent.includes('Android')
    ? 'https://twitter.com/intent/tweet'
    : 'https://twitter.com/compose/tweet'
  const link = `${path}?hashtags=${hashtags}&url=${siteUrl}`

  return (
    <div className="button-wrapper">
      <a
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        onClick={onClick}
      >
        <button className="ladda-button twitter contract">
          <span className="label">Tweet</span>
        </button>
      </a>
    </div>
  )
}

export default TweetComposeButton
