export enum PlayTimeZone {
  WeekdayDaytime = 'weekdayDaytime',
  WeekdayNight = 'weekdayNight',
  WeekendDaytime = 'weekendDaytime',
  WeekendNight = 'WeekendNight',
  Irregular = 'irregular',
  Living = 'living',
}

export const playTimeZoneMaster: Record<PlayTimeZone, { key: string }> = {
  [PlayTimeZone.WeekdayDaytime]: { key: 'playTimeZone.weekdayDaytime' },
  [PlayTimeZone.WeekdayNight]: { key: 'playTimeZone.weekdayNight' },
  [PlayTimeZone.WeekendDaytime]: { key: 'playTimeZone.weekendDaytime' },
  [PlayTimeZone.WeekendNight]: { key: 'playTimeZone.weekendNight' },
  [PlayTimeZone.Irregular]: { key: 'playTimeZone.irregular' },
  [PlayTimeZone.Living]: { key: 'playTimeZone.living' },
}

export enum PlayStyle {
  Leveling = 'leveling',
  Raid = 'raid',
  Crafter = 'crafter',
  Gatherer = 'gatherer',
  Fishing = 'fishing',
  Pvp = 'pvp',
  Ss = 'ss',
  Glamours = 'glamours',
  Talking = 'talking',
  Housing = 'housing',
  Mahjong = 'mahjong',
  Performance = 'performance',
  Leisurely = 'leisurely',
  TreasureHunt = 'treasureHunt',
  RaiseMoney = 'raiseMoney',
  Hunt = 'hunt',
  RolePlay = 'rolePlay',
  VC = 'vc',
  IslandSanctuary = 'islandSanctuary',
}

export const playStyleMaster: Record<PlayStyle, { key: string }> = {
  [PlayStyle.Leveling]: { key: 'playStyle.leveling' },
  [PlayStyle.Raid]: { key: 'playStyle.raid' },
  [PlayStyle.Crafter]: { key: 'playStyle.crafter' },
  [PlayStyle.Gatherer]: { key: 'playStyle.gatherer' },
  [PlayStyle.Fishing]: { key: 'playStyle.fishing' },
  [PlayStyle.Pvp]: { key: 'playStyle.pvp' },
  [PlayStyle.Ss]: { key: 'playStyle.ss' },
  [PlayStyle.Glamours]: { key: 'playStyle.glamours' },
  [PlayStyle.Talking]: { key: 'playStyle.talking' },
  [PlayStyle.Housing]: { key: 'playStyle.housing' },
  [PlayStyle.Mahjong]: { key: 'playStyle.mahjong' },
  [PlayStyle.Performance]: { key: 'playStyle.performance' },
  [PlayStyle.Leisurely]: { key: 'playStyle.leisurely' },
  [PlayStyle.TreasureHunt]: { key: 'playStyle.treasureHunt' },
  [PlayStyle.RaiseMoney]: { key: 'playStyle.raiseMoney' },
  [PlayStyle.Hunt]: { key: 'playStyle.hunt' },
  [PlayStyle.RolePlay]: { key: 'playStyle.rolePlay' },
  [PlayStyle.VC]: { key: 'playStyle.vc' },
  [PlayStyle.IslandSanctuary]: { key: 'playStyle.islandSanctuary' },
}

export enum Job {
  // Tank
  Paladin,
  Warrior,
  DarkKnight,
  Gunbreaker,

  // Healer
  WhiteMage,
  Scholar,
  Astrologian,
  Sage,

  // DPS
  Monk,
  Dragoon,
  Ninja,
  Samurai,
  Reaper,
  Viper,
  Bard,
  Machinist,
  Dancer,
  BlackMage,
  Summoner,
  RedMage,
  Pictmancer,
  BlueMage,

  // Crafter
  Carpenter,
  Blacksmith,
  Armorer,
  Goldsmith,
  Leatherworker,
  Weaver,
  Alchemist,
  Culinarian,

  // Gatherer
  Miner,
  Botanist,
  Fisher,
}

export enum JobType {
  Battle,
  Crafter,
  Gatherer,
}

export interface JobInformation {
  id: Job
  type: JobType
  key: string
  iconPath: string
  isLimited?: boolean
}

export const JobMaster: JobInformation[] = [
  {
    id: Job.Paladin,
    type: JobType.Battle,
    key: 'job.paladin',
    iconPath: './icon/Paladin2.png',
  },
  {
    id: Job.Warrior,
    type: JobType.Battle,
    key: 'job.warrior',
    iconPath: './icon/Warrior2.png',
  },
  {
    id: Job.DarkKnight,
    type: JobType.Battle,
    key: 'job.darkKnight',
    iconPath: './icon/DarkKnight2.png',
  },
  {
    id: Job.Gunbreaker,
    type: JobType.Battle,
    key: 'job.gunbreaker',
    iconPath: './icon/Gunbreaker2.png',
  },
  {
    id: Job.WhiteMage,
    type: JobType.Battle,
    key: 'job.whiteMage',
    iconPath: './icon/WhiteMage2.png',
  },
  {
    id: Job.Scholar,
    type: JobType.Battle,
    key: 'job.scholar',
    iconPath: './icon/Scholar2.png',
  },
  {
    id: Job.Astrologian,
    type: JobType.Battle,
    key: 'job.astrologian',
    iconPath: './icon/Astrologian2.png',
  },
  {
    id: Job.Sage,
    type: JobType.Battle,
    key: 'job.sage',
    iconPath: './icon/Sage2.png',
  },
  {
    id: Job.Monk,
    type: JobType.Battle,
    key: 'job.monk',
    iconPath: './icon/Monk2.png',
  },
  {
    id: Job.Dragoon,
    type: JobType.Battle,
    key: 'job.dragoon',
    iconPath: './icon/Dragoon2.png',
  },
  {
    id: Job.Ninja,
    type: JobType.Battle,
    key: 'job.ninja',
    iconPath: './icon/Ninja2.png',
  },
  {
    id: Job.Samurai,
    type: JobType.Battle,
    key: 'job.samurai',
    iconPath: './icon/Samurai2.png',
  },
  {
    id: Job.Reaper,
    type: JobType.Battle,
    key: 'job.reaper',
    iconPath: './icon/Reaper2.png',
  },
  {
    id: Job.Viper,
    type: JobType.Battle,
    key: 'job.viper',
    iconPath: './icon/Viper2.png',
  },
  {
    id: Job.Bard,
    type: JobType.Battle,
    key: 'job.bard',
    iconPath: './icon/Bard2.png',
  },
  {
    id: Job.Machinist,
    type: JobType.Battle,
    key: 'job.machinist',
    iconPath: './icon/Machinist2.png',
  },
  {
    id: Job.Dancer,
    type: JobType.Battle,
    key: 'job.dancer',
    iconPath: './icon/Dancer2.png',
  },
  {
    id: Job.BlackMage,
    type: JobType.Battle,
    key: 'job.blackMage',
    iconPath: './icon/BlackMage2.png',
  },
  {
    id: Job.Summoner,
    type: JobType.Battle,
    key: 'job.summoner',
    iconPath: './icon/Summoner2.png',
  },
  {
    id: Job.RedMage,
    type: JobType.Battle,
    key: 'job.redMage',
    iconPath: './icon/RedMage2.png',
  },
  {
    id: Job.Pictmancer,
    type: JobType.Battle,
    key: 'job.pictmancer',
    iconPath: './icon/Pictmancer2.png',
  },
  {
    id: Job.BlueMage,
    type: JobType.Battle,
    key: 'job.blueMage',
    iconPath: './icon/BlueMage2.png',
    isLimited: true,
  },
  {
    id: Job.Carpenter,
    type: JobType.Crafter,
    key: 'job.carpenter',
    iconPath: './icon/Carpenter2.png',
  },
  {
    id: Job.Blacksmith,
    type: JobType.Crafter,
    key: 'job.blacksmith',
    iconPath: './icon/Blacksmith2.png',
  },
  {
    id: Job.Armorer,
    type: JobType.Crafter,
    key: 'job.armorer',
    iconPath: './icon/Armorer2.png',
  },
  {
    id: Job.Goldsmith,
    type: JobType.Crafter,
    key: 'job.goldsmith',
    iconPath: './icon/Goldsmith2.png',
  },
  {
    id: Job.Leatherworker,
    type: JobType.Crafter,
    key: 'job.leatherworker',
    iconPath: './icon/Leatherworker2.png',
  },
  {
    id: Job.Weaver,
    type: JobType.Crafter,
    key: 'job.weaver',
    iconPath: './icon/Weaver2.png',
  },
  {
    id: Job.Alchemist,
    type: JobType.Crafter,
    key: 'job.alchemist',
    iconPath: './icon/Alchemist2.png',
  },
  {
    id: Job.Culinarian,
    type: JobType.Crafter,
    key: 'job.culinarian',
    iconPath: './icon/Culinarian2.png',
  },
  {
    id: Job.Miner,
    type: JobType.Gatherer,
    key: 'job.miner',
    iconPath: './icon/Miner2.png',
  },
  {
    id: Job.Botanist,
    type: JobType.Gatherer,
    key: 'job.botanist',
    iconPath: './icon/Botanist2.png',
  },
  {
    id: Job.Fisher,
    type: JobType.Gatherer,
    key: 'job.fisher',
    iconPath: './icon/Fisher2.png',
  },
]

export enum Font {
  kosugi = 'kosugi',
  dot = 'dot',
  sans = 'sans',
  serif = 'serif',
  oswald = 'oswald',
  hachiMaru = 'hachiMaru',
}

export const fontSetMaster = {
  [Font.kosugi]: { key: 'font.kosugi' },
  [Font.sans]: { key: 'font.sans' },
  [Font.serif]: { key: 'font.serif' },
  [Font.oswald]: { key: 'font.oswald' },
  [Font.hachiMaru]: { key: 'font.hachiMaru' },
  [Font.dot]: { key: 'font.dot' },
}

export interface FontSet {
  id: Font
  family: string
  nameFamily?: string
  levelFamily?: string
  style: string
  nameStyle?: string
  labelStyle?: string
  levelStyle?: string
  nameLetterSpacing: number
}

export const FontSetMaster: FontSet[] = [
  {
    id: Font.kosugi,
    family: 'Kosugi Maru',
    style: 'bold',
    labelStyle: 'normal',
    levelStyle: 'normal',
    nameLetterSpacing: 2,
  },
  {
    id: Font.sans,
    family: 'Noto Sans JP',
    style: 'normal',
    nameLetterSpacing: 1,
  },
  {
    id: Font.serif,
    family: 'Noto Serif JP',
    style: '600',
    nameLetterSpacing: 1,
  },
  {
    id: Font.oswald,
    family: 'M PLUS 1p',
    style: '400',
    nameFamily: 'Oswald',
    nameStyle: '300',
    levelFamily: 'Oswald',
    levelStyle: '300',
    nameLetterSpacing: 2,
  },
  {
    id: Font.hachiMaru,
    family: 'Hachi Maru Pop',
    style: 'bold',
    levelFamily: 'Kosugi Maru',
    levelStyle: '300',
    nameLetterSpacing: 1,
  },
  {
    id: Font.dot,
    family: 'DotGothic16',
    style: 'normal',
    nameLetterSpacing: 2,
  },
]
