const Actions = {
  updateId(value: any) {
    return {
      type: 'UpdateId',
      value,
    }
  },
  updateMainJob(value: any) {
    return {
      type: 'UpdateMainJob',
      value,
    }
  },
  updatePlayTimeZones(value: any) {
    return {
      type: 'UpdatePlayTimeZones',
      value,
    }
  },
  updatePlayStyles(value: any) {
    return {
      type: 'UpdatePlayStyles',
      value,
    }
  },
  updateInfoAlign(value: any) {
    return {
      type: 'UpdateInfoAlign',
      value,
    }
  },
  updateOpacity(value: string) {
    return {
      type: 'UpdateOpacity',
      value: Number(value),
    }
  },
  updateInfoTheme(value: any) {
    return {
      type: 'UpdateInfoTheme',
      value,
    }
  },
  UpdateFont(value: any) {
    return {
      type: 'UpdateFont',
      value,
    }
  },
}

export default Actions
