import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import action from './actions/action'
import './Customize.css'
import {
  fontSetMaster,
  PlayStyle,
  playStyleMaster,
  PlayTimeZone,
  playTimeZoneMaster,
} from './enums'
import { GlobalState } from './reducers/reducer'

const Customize: React.FC = () => {
  const dispatch = useDispatch()
  const playTimeZones = useSelector((state: GlobalState) => state.playTimeZones)
  const playStyles = useSelector((state: GlobalState) => state.playStyles)
  const infoAlign = useSelector((state: GlobalState) => state.infoAlign)
  const opacity = useSelector((state: GlobalState) => state.opacity)
  const infoTheme = useSelector((state: GlobalState) => state.infoTheme)
  const font = useSelector((state: GlobalState) => state.font)
  const { t } = useTranslation()

  return (
    <div className="customize">
      <dl>
        <dt>{t('characterInfo.title')}</dt>
        <dd>
          <ul>
            <li>
              <label>
                <input
                  type="radio"
                  name="infoAlign"
                  value="left"
                  checked={infoAlign === 'left'}
                  onChange={(event) =>
                    dispatch(action.updateInfoAlign(event.target.value))
                  }
                />
                {t('characterInfo.leftAlign')}
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  name="infoAlign"
                  value="right"
                  checked={infoAlign === 'right'}
                  onChange={(event) =>
                    dispatch(action.updateInfoAlign(event.target.value))
                  }
                />
                {t('characterInfo.rightAlign')}
              </label>
            </li>
          </ul>
        </dd>
        <dt />
        <dd>
          <ul>
            <li>
              <label>
                <input
                  type="radio"
                  name="infoTheme"
                  value="light"
                  checked={infoTheme === 'light'}
                  onChange={(event) =>
                    dispatch(action.updateInfoTheme(event.target.value))
                  }
                />
                {t('characterInfo.light')}
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  name="infoTheme"
                  value="dark"
                  checked={infoTheme === 'dark'}
                  onChange={(event) =>
                    dispatch(action.updateInfoTheme(event.target.value))
                  }
                />
                {t('characterInfo.dark')}
              </label>
            </li>
          </ul>
        </dd>
        <dd>
          <label>
            {t('characterInfo.opacity')}
            <input
              type="range"
              name="opacity"
              min={0}
              max={1}
              step={0.1}
              value={opacity}
              onChange={(event) =>
                dispatch(action.updateOpacity(event.target.value))
              }
              list="tlist"
            />
            <datalist id="tlist">
              <option value={0} />
              <option value={0.7} />
              <option value={1} />
            </datalist>
          </label>
        </dd>
        <dt />
        <dt>{t('font.title')}</dt>
        <dd>
          <ul>
            {Object.entries(fontSetMaster).map(([id, { key }]) => (
              <li key={id}>
                <label>
                  <input
                    type="radio"
                    name="font"
                    value={id}
                    checked={font === id}
                    onChange={(event) =>
                      dispatch(action.UpdateFont(event.target.value))
                    }
                    className="form-check-input"
                  />
                  {t(key)}
                </label>
              </li>
            ))}
          </ul>
        </dd>
        <dt>{t('playTimeZone.title')}</dt>
        <dd>
          <ul>
            {Object.entries(playTimeZoneMaster).map(([id, { key }]) => (
              <li key={id}>
                <label>
                  <input
                    type="checkbox"
                    name="playTimeZones"
                    value={id}
                    checked={playTimeZones.includes(id as PlayTimeZone)}
                    onChange={(event) =>
                      dispatch(action.updatePlayTimeZones(event.target.value))
                    }
                    className="form-check-input"
                  />
                  {t(key)}
                </label>
              </li>
            ))}
          </ul>
        </dd>
        <dt>{t('playStyle.title')}</dt>
        <dd>
          <ul>
            {Object.entries(playStyleMaster).map(([id, { key }]) => (
              <li key={id}>
                <label>
                  <input
                    type="checkbox"
                    name="playStyles"
                    value={id}
                    checked={playStyles.includes(id as PlayStyle)}
                    onChange={(event) =>
                      dispatch(action.updatePlayStyles(event.target.value))
                    }
                    className="form-check-input"
                  />
                  {t(key)}
                </label>
              </li>
            ))}
          </ul>
        </dd>
      </dl>
    </div>
  )
}

export default Customize
