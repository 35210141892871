import { Reducer } from 'redux'
import { Font, Job, PlayStyle, PlayTimeZone } from '../enums'

export interface GlobalState {
  id: string
  mainJob: Job
  playTimeZones: PlayTimeZone[]
  playStyles: PlayStyle[]
  infoAlign: 'left' | 'right'
  opacity: number
  infoTheme: 'light' | 'dark'
  font: Font
}

export const initialState: GlobalState = {
  id: '',
  mainJob: Job.Paladin,
  playTimeZones: [],
  playStyles: [],
  infoAlign: 'left',
  opacity: 0.7,
  infoTheme: 'light',
  font: Font.kosugi,
}

const reducer: Reducer = (state, action: any) => {
  switch (action.type) {
    case 'UpdateId': {
      return {
        ...state,
        id: action.value,
      }
    }
    case 'UpdateMainJob': {
      return {
        ...state,
        mainJob: action.value,
      }
    }
    case 'UpdatePlayTimeZones': {
      if (state.playTimeZones.includes(action.value)) {
        return {
          ...state,
          playTimeZones: state.playTimeZones.filter(
            (playTimeZone: any) => playTimeZone !== action.value
          ),
        }
      } else {
        return {
          ...state,
          playTimeZones: [...state.playTimeZones, action.value],
        }
      }
    }
    case 'UpdatePlayStyles': {
      if (state.playStyles.includes(action.value)) {
        return {
          ...state,
          playStyles: state.playStyles.filter(
            (playStyle: any) => playStyle !== action.value
          ),
        }
      } else {
        return {
          ...state,
          playStyles: [...state.playStyles, action.value],
        }
      }
    }
    default: {
      return state
    }
    case 'UpdateInfoAlign': {
      return {
        ...state,
        infoAlign: action.value,
      }
    }
    case 'UpdateOpacity': {
      return {
        ...state,
        opacity: action.value,
      }
    }
    case 'UpdateInfoTheme': {
      return {
        ...state,
        infoTheme: action.value,
      }
    }
    case 'UpdateFont': {
      return {
        ...state,
        font: action.value,
      }
    }
  }
}

export default reducer
