import React from 'react'
import './Button.css'

interface Props {
  onClick: (e: any) => void
  dataLoading: boolean
}

const GenerateButton: React.FC<Props> = ({ onClick, dataLoading }: Props) => {
  return (
    <div className="button-wrapper">
      <button
        className="ladda-button orange contract"
        onClick={onClick}
        data-loading={dataLoading}
      >
        <span className="label">Generate</span>
        <span className="spinner" />
      </button>
    </div>
  )
}

export default GenerateButton
