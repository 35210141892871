import { saveAs } from 'file-saver'
import { t } from 'i18next'
import React from 'react'
import * as ReactGA from 'react-ga'
import { Group, Layer, Rect, Stage, Text } from 'react-konva'
import Character from './Character'
import URLImage from './components/Konva/URLImage'
import './Canvas.css'
import {
  FontSet,
  Job,
  JobInformation,
  JobMaster,
  PlayStyle,
  playStyleMaster,
  PlayTimeZone,
  playTimeZoneMaster,
} from './enums'

interface JobLevelProps {
  x: number
  y: number
  scale: number
  text: string
  fontSet: FontSet
  fontColor: string
  job: JobInformation
}

const JobLevel: React.FC<JobLevelProps> = (props) => {
  let fill = props.fontColor
  if (props.text === '100' || (props.job.isLimited && props.text === '80')) {
    fill = '#de5917'
  }
  return (
    <Group>
      <URLImage
        x={props.x}
        y={props.y}
        width={props.scale * 36}
        height={props.scale * 36}
        image={props.job.iconPath}
      />
      <Text
        x={props.x}
        y={props.y + 40 * props.scale}
        width={props.scale * 36}
        height={props.scale * 34}
        fontSize={props.scale * 21}
        fontFamily={props.fontSet.levelFamily || props.fontSet.family}
        fontStyle={props.fontSet.levelStyle || props.fontSet.style}
        text={props.text}
        fill={fill}
        align="center"
      />
    </Group>
  )
}

const JobLevels: React.FC<any> = (props: any) => {
  const list = []
  const interval = 44

  // 1段目
  let x = 0
  for (let i = 0; i < 4; i++) {
    list.push(
      <JobLevel
        key={i}
        x={props.x + x}
        y={props.y}
        text={props.levels[i]}
        scale={props.scale}
        fontSet={props.fontSet}
        fontColor={props.fontColor}
        job={JobMaster[i]}
      />
    )
    x += props.scale * interval
  }
  for (let i = 4; i < 8; i++) {
    list.push(
      <JobLevel
        key={i}
        x={props.x + x}
        y={props.y}
        text={props.levels[i]}
        scale={props.scale}
        fontSet={props.fontSet}
        fontColor={props.fontColor}
        job={JobMaster[i]}
      />
    )
    x += props.scale * interval
  }

  // 2段目
  x = 0
  for (let i = 8; i < 17; i++) {
    list.push(
      <JobLevel
        key={i}
        x={props.x + x}
        y={props.y + props.scale * 70}
        text={props.levels[i]}
        scale={props.scale}
        fontSet={props.fontSet}
        fontColor={props.fontColor}
        job={JobMaster[i]}
      />
    )
    x += props.scale * interval
  }

  // 3段目
  x = 0
  for (let i = 17; i < 22; i++) {
    list.push(
      <JobLevel
        key={i}
        x={props.x + x}
        y={props.y + props.scale * 140}
        text={props.levels[i]}
        scale={props.scale}
        fontSet={props.fontSet}
        fontColor={props.fontColor}
        job={JobMaster[i]}
      />
    )
    x += props.scale * interval
  }

  // 4段目
  x = 0
  for (let i = 22; i < 33; i++) {
    list.push(
      <JobLevel
        key={i}
        x={props.x + x}
        y={props.y + props.scale * 210}
        text={props.levels[i]}
        scale={props.scale}
        fontSet={props.fontSet}
        fontColor={props.fontColor}
        job={JobMaster[i]}
      />
    )
    x += props.scale * interval
  }

  return <Group>{list}</Group>
}

interface CharacterInfoProps {
  character: Character
  mainJob: Job
  playTimeZones: PlayTimeZone[]
  playStyles: PlayStyle[]
  x: number
  y: number
  scale: number
  fontSet: FontSet
  fontColor: string
}

const CharacterInfo: React.FC<CharacterInfoProps> = (
  props: CharacterInfoProps
) => {
  const playTimeZones = props.playTimeZones
    .filter((playTimeZone) => playTimeZoneMaster[playTimeZone])
    .map((playTimeZone) => t(playTimeZoneMaster[playTimeZone].key))
    .join(t('delimiter'))
  const playStyles = props.playStyles
    .filter((playStyle) => playStyleMaster[playStyle])
    .map((playStyle) => t(playStyleMaster[playStyle].key))
    .join(t('delimiter'))

  return (
    <Group>
      <Text
        x={props.x}
        y={props.y}
        text={props.character.name}
        fontSize={props.scale * 46}
        fontFamily={props.fontSet.nameFamily || props.fontSet.family}
        fontStyle={props.fontSet.nameStyle || props.fontSet.style}
        letterSpacing={props.fontSet.nameLetterSpacing}
        fill={props.fontColor}
        height={props.scale * 50}
        verticalAlign="bottom"
      />
      <Text
        x={props.x + 260 * props.scale}
        y={props.y + 60 * props.scale}
        text={t('world')}
        fontSize={props.scale * 14}
        fontFamily={props.fontSet.family}
        fontStyle={props.fontSet.labelStyle || props.fontSet.style}
        fill={props.fontColor}
        height={props.scale * 50}
        verticalAlign="top"
      />
      <Text
        x={props.x + 260 * props.scale}
        y={props.y + 80 * props.scale}
        text={`${props.character.server}(${props.character.data_center})`}
        fontSize={props.scale * 25}
        fontFamily={props.fontSet.family}
        fontStyle={props.fontSet.style}
        fill={props.fontColor}
        height={props.scale * 35}
        verticalAlign="middle"
      />

      <Text
        x={props.x}
        y={props.y + 60 * props.scale}
        text={t('mainJob.title')}
        fontSize={props.scale * 14}
        fontFamily={props.fontSet.family}
        fontStyle={props.fontSet.labelStyle || props.fontSet.style}
        fill={props.fontColor}
        height={props.scale * 50}
        verticalAlign="top"
      />
      <URLImage
        x={props.x}
        y={props.y + 80 * props.scale}
        width={props.scale * 33}
        height={props.scale * 33}
        image={JobMaster[props.mainJob].iconPath}
      />
      <Text
        x={props.x + 40 * props.scale}
        y={props.y + 80 * props.scale}
        text={t(JobMaster[props.mainJob].key)}
        fontSize={props.scale * 22}
        fontFamily={props.fontSet.family}
        fontStyle={props.fontSet.style}
        fill={props.fontColor}
        height={props.scale * 35}
        verticalAlign="middle"
      />

      <Text
        x={props.x}
        y={props.y + 130 * props.scale}
        text={t('playTimeZone.title')}
        fontSize={props.scale * 14}
        fontFamily={props.fontSet.family}
        fontStyle={props.fontSet.labelStyle || props.fontSet.style}
        fill={props.fontColor}
        height={props.scale * 50}
        verticalAlign="top"
      />
      <Text
        x={props.x}
        y={props.y + 150 * props.scale}
        text={playTimeZones}
        fontSize={props.scale * 22}
        fontFamily={props.fontSet.family}
        fontStyle={props.fontSet.style}
        fill={props.fontColor}
        width={props.scale * 550}
        height={props.scale * 70}
        verticalAlign="top"
        wrap="word"
      />

      <Text
        x={props.x}
        y={props.y + 195 * props.scale}
        text={t('playStyle.title')}
        fontSize={props.scale * 14}
        fontFamily={props.fontSet.family}
        fontStyle={props.fontSet.labelStyle || props.fontSet.style}
        fill={props.fontColor}
        height={props.scale * 50}
        verticalAlign="top"
      />
      <Text
        x={props.x}
        y={props.y + 215 * props.scale}
        text={playStyles}
        fontSize={props.scale * 22}
        fontFamily={props.fontSet.family}
        fontStyle={props.fontSet.style}
        fill={props.fontColor}
        width={props.scale * 550}
        height={props.scale * 80}
        verticalAlign="top"
        lineHeight={1.1}
        wrap="word"
      />
    </Group>
  )
}

interface CanvasProps {
  character: Character
  mainJob: Job
  playTimeZones: PlayTimeZone[]
  playStyles: PlayStyle[]
  infoAlign: string
  opacity: number
  infoTheme: string
  inputImage: string
  fontSet: FontSet
}

interface CanvasStates {
  imageX: number
  imageY: number
  originalImageWidth: number
  originalImageHeight: number
  imageScale: number
  stageWidth: number
  stageHeight: number
  lastDist: number
  imageDraggable: boolean
}

const ASPECT_RATIO = 0.525

class Canvas extends React.Component<CanvasProps, CanvasStates> {
  stage: any
  container: any
  imageWidth = 0
  imageHeight = 0

  constructor(props: CanvasProps) {
    super(props)
    this.state = {
      imageX: 0,
      imageY: 0,
      originalImageWidth: 0,
      originalImageHeight: 0,
      imageScale: 1.2,
      stageWidth: 1200,
      stageHeight: 630,
      lastDist: 0,
      imageDraggable: true,
    }

    const image = new Image()
    image.addEventListener('load', () => {
      this.setState({
        originalImageWidth: image.width,
        originalImageHeight: image.height,
      })
    })
    image.src = this.props.inputImage

    this.handleWheel = this.handleWheel.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
    this.handleDragEnd = this.handleDragEnd.bind(this)
    this.dragBoundFunc = this.dragBoundFunc.bind(this)
  }

  componentDidMount() {
    this.resizeStage()
    // here we should add listener for "container" resize
    // take a look here https://developers.google.com/web/updates/2016/10/resizeobserver
    // for simplicity I will just listen window resize
    window.addEventListener('resize', this.resizeStage)
  }

  resizeStage = () => {
    const width =
      this.container && this.container.offsetWidth < 1200
        ? this.container.offsetWidth
        : 1200
    this.setState({
      stageWidth: width,
      stageHeight: width * ASPECT_RATIO,
    })
  }

  handleDownloadClick() {
    ReactGA.ga('send', 'event', 'Click', 'download')

    const dataURL = this.stage.toDataURL({
      mimeType: 'image/jpeg',
      pixelRatio: (1200 / this.state.stageWidth) * 1.5, // ピクセルと出力画像の比 上げると画像の画質が上がる
    })

    const base64ToBlob = (uri: string) => {
      const parse = uri.slice(5).split(/[,;]/)
      const binStr = atob(parse.pop() || '')
      const l = binStr.length
      const array = new Uint8Array(l)

      for (let i = 0; i < l; i++) {
        array[i] = binStr.charCodeAt(i)
      }

      return new Blob([array], { type: parse[0] })
    }

    const blob = base64ToBlob(dataURL)
    saveAs(blob, 'キャラカ.jpg')
  }

  getDistance = (p1: any, p2: any): number => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
  }

  handleTouchStart(e: any) {
    if (e.evt.touches.length > 1) {
      this.setState({ imageDraggable: false })
    }
  }

  handleTouchMove(e: any) {
    e.evt.preventDefault()

    const touch1 = e.evt.touches[0]
    const touch2 = e.evt.touches[1]

    if (touch1 && touch2) {
      const dist = this.getDistance(
        {
          x: touch1.clientX,
          y: touch1.clientY,
        },
        {
          x: touch2.clientX,
          y: touch2.clientY,
        }
      )
      if (!this.state.lastDist) {
        this.setState({ lastDist: dist })
      }
      let newScale = (this.state.imageScale * dist) / this.state.lastDist
      if (newScale < 1) {
        newScale = 1
      }
      this.setState({
        imageScale: newScale,
        lastDist: dist,
      })
    }
  }

  handleTouchEnd(e: any) {
    this.setState({ imageDraggable: true, lastDist: 0 })
  }

  handleWheel(e: any) {
    e.evt.preventDefault()

    const scaleBy = 1.007

    let newScale =
      e.evt.deltaY < 0
        ? this.state.imageScale * scaleBy
        : this.state.imageScale / scaleBy
    if (newScale < 1) {
      newScale = 1
    }
    this.setState({ imageScale: newScale })
  }

  handleDragEnd(e: any) {
    this.setState({
      imageX: e.target.x(),
      imageY: e.target.y(),
    })
  }

  dragBoundFunc(pos: any) {
    // 両端を設定
    let leftEdge = 0
    let rightEdge = this.state.stageWidth
    if (this.props.opacity === 1) {
      if (this.props.infoAlign === 'left') {
        leftEdge = this.state.stageWidth / 2
      } else {
        rightEdge = this.state.stageWidth / 2
      }
    }

    let newX = pos.x
    let newY = pos.y

    if (pos.x > leftEdge) {
      newX = leftEdge
    }
    if (pos.y > 0) {
      newY = 0
    }
    if (pos.x + this.imageWidth * this.state.imageScale < rightEdge) {
      newX = rightEdge - this.imageWidth * this.state.imageScale
    }
    if (
      pos.y + this.imageHeight * this.state.imageScale <
      this.state.stageHeight
    ) {
      newY = this.state.stageHeight - this.imageHeight * this.state.imageScale
    }
    return {
      x: newX,
      y: newY,
    }
  }

  render() {
    const scale = this.state.stageWidth / 1200
    let rectColor = '#fff'
    let fontColor = '#333'
    let fontSubColor = '#666'
    if (this.props.infoTheme === 'dark') {
      rectColor = '#222'
      fontColor = '#EEE'
      fontSubColor = '#AAA'
    }

    const x1 = this.props.infoAlign === 'left' ? 0 : this.state.stageWidth * 0.5
    let imageWidth = this.state.stageWidth
    let imageHeight = this.state.stageHeight
    const imageAspectRatio =
      this.state.originalImageHeight / this.state.originalImageWidth
    if (imageAspectRatio > ASPECT_RATIO) {
      const ratio = this.state.stageWidth / this.state.originalImageWidth
      imageHeight = this.state.originalImageHeight * ratio
    } else {
      const ratio = this.state.stageHeight / this.state.originalImageHeight
      imageWidth = this.state.originalImageWidth * ratio
    }

    this.imageWidth = imageWidth
    this.imageHeight = imageHeight

    const now = new Date()
    const date = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`

    return (
      <div className="canvas-wrapper" ref={(node) => (this.container = node)}>
        <Stage
          className="stage"
          width={this.state.stageWidth}
          height={this.state.stageHeight}
          ref={(ref) => (this.stage = ref)}
          onWheel={this.handleWheel}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
        >
          <Layer>
            <Rect
              x={0}
              y={0}
              width={this.state.stageWidth}
              height={this.state.stageHeight}
              fill="#333"
            />
            <URLImage
              x={this.state.imageX}
              y={this.state.imageY}
              width={imageWidth}
              height={imageHeight}
              image={this.props.inputImage}
              draggable={this.state.imageDraggable}
              onMouseEnter={() =>
                (this.stage.container().style.cursor = 'pointer')
              }
              onMouseLeave={() =>
                (this.stage.container().style.cursor = 'default')
              }
              onDragEnd={this.handleDragEnd}
              dragBoundFunc={this.dragBoundFunc}
              scale={this.state.imageScale}
            />

            <Rect
              x={x1}
              y={0}
              width={this.state.stageWidth / 2}
              height={this.state.stageHeight}
              fill={rectColor}
              opacity={this.props.opacity}
            />
            <CharacterInfo
              mainJob={this.props.mainJob}
              character={this.props.character}
              playTimeZones={this.props.playTimeZones}
              playStyles={this.props.playStyles}
              x={x1 + this.state.stageWidth * 0.03}
              y={this.state.stageWidth * 0.027}
              scale={scale}
              fontSet={this.props.fontSet}
              fontColor={fontColor}
            />
            <JobLevels
              levels={this.props.character.levels}
              x={x1 + this.state.stageWidth * 0.027}
              y={this.state.stageHeight * 0.53}
              fontSet={this.props.fontSet}
              fontColor={fontColor}
              scale={scale}
            />
            <Text
              x={x1 + this.state.stageWidth * 0.42}
              y={this.state.stageHeight * 0.97}
              text={date}
              fontSize={scale * 15}
              fontFamily="M PLUS 1p"
              fontStyle="normal"
              fill={fontSubColor}
            />
            <Text
              x={x1 + this.state.stageWidth * 0.03}
              y={this.state.stageHeight * 0.97}
              text="(C) SQUARE ENIX CO., LTD. All Rights Reserved."
              fontSize={scale * 13}
              fontFamily="M PLUS 1p"
              fontStyle="normal"
              fill={fontSubColor}
            />
          </Layer>
        </Stage>
      </div>
    )
  }
}

export default Canvas
