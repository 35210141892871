import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './i18n/en.json'
import ja from './i18n/ja.json'

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ja,
      en,
    },
    fallbackLng: 'ja',

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
