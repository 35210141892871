import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import './reset.css'
import './index.css'
import { save, load } from 'redux-localstorage-simple'
import App from './App'
import i18n from './i18n'
import reducer, { initialState } from './reducers/reducer'
import * as serviceWorker from './serviceWorker'

const createStoreWithMiddleware = applyMiddleware(save({ namespace: 'input' }))(
  createStore
)

export const store = createStoreWithMiddleware(
  reducer,
  load({ namespace: 'input', preloadedState: initialState })
)

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
