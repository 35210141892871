import React from 'react'
import { Image } from 'react-konva'
// @ts-ignore
import useImage from 'use-image'

interface URIImageProps {
  x: number
  y: number
  width?: number
  height?: number
  image: string
  draggable?: boolean
  onMouseEnter?: any
  onMouseLeave?: any
  onDragStart?: any
  onDragEnd?: any
  dragBoundFunc?: any
  scale?: number
}

const URLImage: React.FC<URIImageProps> = (props: URIImageProps) => {
  const [image] = useImage(props.image, 'anonymous')

  return (
    <Image
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      image={image}
      draggable={props.draggable}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onDragStart={props.onDragStart}
      onDragEnd={props.onDragEnd}
      dragBoundFunc={props.dragBoundFunc}
      scaleX={props.scale}
      scaleY={props.scale}
    />
  )
}

export default URLImage
