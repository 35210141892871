class Character {
  name = ''
  levels: string[] = []
  image = ''
  server = ''
  data_center = ''
  birthday = ''
  race = ''
  tribe = ''
  gender = ''
  guardian = ''
  hometown = ''
  grand_company = ''
  grand_company_rank = ''
  free_company = ''
  free_company_image = ''

  constructor(init?: Partial<Character>) {
    Object.assign(this, init)
  }
}

export default Character
