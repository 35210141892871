import styled from '@emotion/styled'
import React, { useEffect } from 'react'

const AdTypes = [
  'underTheHeader',
  'pcTopLeft',
  'pcTopRight',
  'pcTopLarge',
  'pcBottomLeft',
  'pcBottomRight',
  'pcBottomLarge',
  'spBottom',
] as const
type AdType = typeof AdTypes[number]
const adTypes: { [key in AdType]: string } = {
  underTheHeader: '8937370060',
  pcTopLeft: '4962234528',
  pcTopRight: '5992051646',
  pcTopLarge: '5234407700',
  pcBottomLeft: '9404683043',
  pcBottomRight: '6466887887',
  pcBottomLarge: '8037630010',
  spBottom: '2154747941',
}

type Props = {
  adType: AdType
  format?: 'auto' | 'rectangle' | 'vertical' | 'horizontal'
  responsive?: boolean
}

export const AdsenseAd: React.FC<Props> = ({
  adType,
  format = 'auto',
  responsive = false,
}) => {
  useEffect(() => {
    // @ts-ignore
    window.adsbygoogle && window.adsbygoogle.push({})
  }, [])
  return (
    <Ins
      className="adsbygoogle"
      data-ad-client="ca-pub-2755284894317377"
      data-ad-slot={adTypes[adType]}
      data-ad-format={format}
      data-full-width-responsive={responsive}
    />
  )
}

const Ins = styled.ins`
  display: inline-block;
`
