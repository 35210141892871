import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const Footer: React.FC = () => {
  const { t, i18n } = useTranslation()
  const handleChange = useCallback(
    (e: any) => {
      i18n.changeLanguage(e.target.value)
    },
    [i18n]
  )

  return (
    <Wrapper>
      <FooterContent>
        <Top>
          <Language>
            <label>
              Language
              <LanguageSelect
                name="language"
                value={i18n.language}
                onChange={handleChange}
              >
                <option value="ja">日本語</option>
                <option value="en">English</option>
              </LanguageSelect>
            </label>
          </Language>
          <Feedback>
            {t('feedback')}
            <a
              href="https://twitter.com/kazetaro_kikori"
              target="_blank"
              rel="noreferrer noopener"
            >
              @kazetaro_kikori
            </a>
          </Feedback>
        </Top>
        <Separator />
        <Bottom>
          <Copyright>
            <P>(C) SQUARE ENIX CO., LTD. All Rights Reserved.</P>
          </Copyright>
        </Bottom>
        <Hide>
          <FontGet1>a</FontGet1>
          <FontGet2>a</FontGet2>
          <FontGet3>a</FontGet3>
          <FontGet4>a</FontGet4>
          <FontGet5>a</FontGet5>
          <FontGet6>a</FontGet6>
          <FontGet7>a</FontGet7>
        </Hide>
      </FooterContent>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const FooterContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  padding: 12px;
`

const Top = styled.div`
  box-sizing: border-box;
  width: 100%;
`
const Separator = styled.div`
  margin-top: 12px;
  border-bottom: 1px solid #777;
  width: 100%;
`
const Bottom = styled.div`
  margin-top: 12px;
  width: 100%;
  box-sizing: border-box;
`

const Language = styled.div`
  font-size: 12px;
`
const Feedback = styled.div`
  margin-top: 6px;
  font-size: 12px;
  margin-left: 12px;
`
const P = styled.p`
  margin: 0;
`
const Copyright = styled.div`
  font-size: 10px;
`
const LanguageSelect = styled.select`
  margin-left: 4px;
`
const Hide = styled.div`
  visibility: hidden;
  width: 1px;
  height: 1px;
  font-size: 1px;
`
const FontGet1 = styled.span`
  font-family: Kosugi Maru;
`
const FontGet2 = styled.span`
  font-family: 'Noto Sans JP';
`
const FontGet3 = styled.span`
  font-family: 'Noto Serif JP';
`
const FontGet4 = styled.span`
  font-family: M PLUS 1p;
`
const FontGet5 = styled.span`
  font-family: Oswald;
`
const FontGet6 = styled.span`
  font-family: Hachi Maru Pop;
`
const FontGet7 = styled.span`
  font-family: DotGothic16;
`
